import React from 'react'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Card from '../components/card'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>SourcingSpace - Home</title>
        <meta name="description" content="Sourcing Talent Agency" />
        <meta property="og:title" content="SourcingSpace - Home" />
        <meta property="og:description" content="Sourcing Talent Agency" />
      </Helmet>
      <div className="home-navbar">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <div className="home-branding">
            <img
              alt="image"
              src="/playground_assets/logo-color2-200h.png"
              className="home-image"
            />
            <span className="home-text">
              <span>SourcingSpace</span>
              <br></br>
            </span>
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 128h768v768h-768z"></path>
            </svg>
            <span className="home-text03">
              <span>A Talent Sourcing Agency</span>
              <br></br>
            </span>
          </div>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="home-desktop-menu"
          >
            <nav className="home-links">
              <a
                href="mailto:info@sourcingspace.com?subject=Wij zijn geïnteresseerd om samen met jullie te werken"
                className="home-link button"
              >
                <span className="home-text06">
                  <span>Contact ons</span>
                  <br></br>
                </span>
              </a>
            </nav>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <img
              alt="image"
              src="/playground_assets/group%202084%5B1%5D.svg"
              className="home-hamburger"
            />
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div className="home-top">
              <div className="home-branding1">
                <span className="home-text09">Remarkable</span>
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M128 128h768v768h-768z"></path>
                </svg>
                <span className="home-text10">Remarkable</span>
              </div>
              <div data-thq="thq-close-menu" className="home-menu-close">
                <svg viewBox="0 0 1024 1024" className="home-icon04">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="home-links1">
              <button className="home-button button">
                <span>Our offers</span>
              </button>
              <button className="home-button1 button">
                <span>Get a fast quote</span>
              </button>
              <button className="home-button2 button">Menu</button>
            </nav>
            <div className="home-social-bar">
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon06">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon08">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon10">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon12">
                <path d="M585.143 857.143c-5.714-33.143-27.429-147.429-80-284.571-0.571 0-1.714 0.571-2.286 0.571 0 0-222.286 77.714-294.286 234.286-3.429-2.857-8.571-6.286-8.571-6.286 65.143 53.143 148 85.714 238.857 85.714 52 0 101.143-10.857 146.286-29.714zM479.429 510.286c-9.143-21.143-19.429-42.286-30.286-63.429-193.143 57.714-378.286 53.143-384.571 53.143-0.571 4-0.571 8-0.571 12 0 96 36.571 184 96 250.286v0c102.286-182.286 304.571-247.429 304.571-247.429 5.143-1.714 10.286-2.857 14.857-4.571zM418.286 389.143c-65.143-115.429-134.286-209.143-139.429-216-104.571 49.143-182.286 145.714-206.857 261.714 9.714 0 166.286 1.714 346.286-45.714zM809.143 571.429c-8-2.286-112.571-35.429-233.714-16.571 49.143 135.429 69.143 245.714 73.143 268 84-56.571 143.429-146.857 160.571-251.429zM349.143 148c-0.571 0-0.571 0-1.143 0.571 0 0 0.571-0.571 1.143-0.571zM686.286 230.857c-65.714-58.286-152.571-93.714-247.429-93.714-30.286 0-60 4-88.571 10.857 5.714 7.429 76.571 100.571 140.571 218.286 141.143-52.571 194.286-133.714 195.429-135.429zM813.714 508c-1.143-88.571-32.571-170.286-85.143-234.286-1.143 1.143-61.143 88-209.143 148.571 8.571 17.714 17.143 36 25.143 54.286 2.857 6.286 5.143 13.143 8 19.429 129.143-16.571 256.571 11.429 261.143 12zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="home-icon14">
                <path d="M1013.8 307.2c0 0-10-70.6-40.8-101.6-39-40.8-82.6-41-102.6-43.4-143.2-10.4-358.2-10.4-358.2-10.4h-0.4c0 0-215 0-358.2 10.4-20 2.4-63.6 2.6-102.6 43.4-30.8 31-40.6 101.6-40.6 101.6s-10.2 82.8-10.2 165.8v77.6c0 82.8 10.2 165.8 10.2 165.8s10 70.6 40.6 101.6c39 40.8 90.2 39.4 113 43.8 82 7.8 348.2 10.2 348.2 10.2s215.2-0.4 358.4-10.6c20-2.4 63.6-2.6 102.6-43.4 30.8-31 40.8-101.6 40.8-101.6s10.2-82.8 10.2-165.8v-77.6c-0.2-82.8-10.4-165.8-10.4-165.8zM406.2 644.8v-287.8l276.6 144.4-276.6 143.4z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <header className="home-hero">
        <div className="home-content">
          <h1 className="home-title">
            <span>
              Wij
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text14">zoeken</span>
            <span>
              {' '}
              en
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text16">benaderen</span>
            <span> kandidaten.</span>
            <br></br>
          </h1>
          <span className="home-description">
            Bespaar tijd en hou je bezig met de leukere taken binnen het
            recruitment proces.
          </span>
          <img
            alt="image"
            src="/playground_assets/line1-300w.png"
            className="home-image01"
          />
        </div>
        <div className="home-image02">
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1519670107408-15dc1b3ecb1c?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDQzfHxwZW9wbGUlMjB3b3JrJTIweWVsbG93fGVufDB8fHx8MTY3MDk2MjYwNw&amp;ixlib=rb-4.0.3&amp;w=1400"
            className="home-image03"
          />
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1507207611509-ec012433ff52?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE0fHxwZW9wbGUlMjB3b3JrfGVufDB8fHx8MTY3MDk2MjU4NA&amp;ixlib=rb-4.0.3&amp;w=1400"
            className="home-image04"
          />
        </div>
      </header>
      <section className="home-mission">
        <h2 className="home-text19">
          <span>
            Wij leggen je in de watten bij de eerste stappen van het recruitment
            proces: het zoeken en benaderen van kandidaten.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
        </h2>
        <a href="#quote-next" className="home-link1 button">
          <span className="home-text22">
            <span>Leer meer over onze missie</span>
            <br></br>
          </span>
        </a>
      </section>
      <section className="home-improve">
        <div className="home-heading">
          <h2 className="home-text25">
            <span>Persoonlijk en creatief</span>
            <br></br>
          </h2>
          <p className="home-text28">
            <span>
              Wij zorgen dat het eerste contact succesvol wordt! Benader
              kandidaten vanuit je eigen LinkedIn profiel en blaas ze omver met
              creatieve berichten.
            </span>
            <br></br>
          </p>
        </div>
        <div className="home-tabs">
          <div className="home-switches">
            <span className="home-switch switch switch-active">
              <span>Vind professionals buiten jouw netwerk</span>
              <br></br>
            </span>
          </div>
          <div className="home-content1">
            <div className="home-details">
              <div className="home-header">
                <h2 className="home-text33">
                  <span>Onvindbare kandidaten vinden</span>
                  <br></br>
                </h2>
                <div className="home-description1">
                  <p className="home-text36">
                    <span>
                      Door onze expertise hebben we methodes ontwikkeld waarbij
                      we onvindbare kandidaten kunnen vinden. Dit zijn
                      kandidaten die weinig tot niet gecontacteerd zijn door
                      andere recruiters wat betekent dat ze sneller op jouw
                      bericht zitten te wachten.
                    </span>
                    <br></br>
                  </p>
                  <p className="home-text39">
                    <span>
                      Hierdoor genereren wij meer interactie en daardoor meer
                      resultaat.
                    </span>
                    <br></br>
                  </p>
                </div>
              </div>
            </div>
            <div className="home-image05">
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1464716821973-e1031cfa43dc?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fGZpbmR8ZW58MHx8fHwxNjcwOTYyNTMx&amp;ixlib=rb-4.0.3&amp;w=600"
                loading="eager"
                className="home-image06"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="home-services">
        <h2 className="home-text42">
          <span>Wie wij zijn</span>
          <br></br>
        </h2>
        <div className="home-cards">
          <div className="home-row">
            <Card
              Title="Hoge conversie op reacties"
              Description="Wij gebruiken de meest efficiente copywriting wat de kandidaat niet laat wachten om een reactie terug te sturen!"
              rootClassName="card-root-class-name3"
            ></Card>
            <Card
              Icon="/playground_assets/group%201644%201.svg"
              Title="Unbiased sourcing"
              Description="Wij geloven in 0 connecties, 0 biases. We willen iedereen gelijke kansen geven, ook kandidaten dat geen netwerk hebben."
              rootClassName="card-root-class-name2"
            ></Card>
          </div>
          <div className="home-row1">
            <Card
              Icon="/playground_assets/group%201645%201.svg"
              Title="Churn preventie"
              Description="Door onze branche kennis in de recruitment markt, weten wij welke kandidaten sneller churnen. Wij zoeken voor jou de kandidaten die langer blijven dan wat je normaal gewend bent."
              rootClassName="card-root-class-name"
            ></Card>
            <Card
              Icon="/playground_assets/group%201646%201.svg"
              Title="Direct contact"
              Description="Wij zijn een gedreven team dat altijd klaar voor je staat wanneer nodig."
              rootClassName="card-root-class-name1"
            ></Card>
          </div>
        </div>
      </section>
      <section className="home-testimonials">
        <div className="home-container1">
          <div className="home-content2">
            <div className="active-quote quote">
              <img
                alt="image"
                src="/assets/Vera.png"
                role="quote-avatar"
                className="home-image07"
              />
              <p role="quote-content" className="home-quote1">
                &quot;Ik kan met veel enthousiasme vertellen dat SourcingSpace
                geweldig werk levert. Zo vinden ze niet alleen exact de
                kandidaten waar ik naar zoek, ze leggen ook de kandidaten voor
                die bijna altijd reageren binnen no-time!&quot;
              </p>
              <div className="home-author">
                <span role="quote-author" className="home-name">
                  Vera B.
                </span>
                <span role="quote-origin" className="home-origin">
                  Talent manager
                </span>
              </div>
            </div>
            <div className="quote">
              <img
                alt="image"
                src="/assets/Jonas.png"
                role="quote-avatar"
                className="home-image08"
              />
              <p role="quote-content" className="home-quote3">
                <span className="home-text45">
                  &quot;Goed werk leveren kunnen ze zeker, op tijd ook.&quot;
                </span>
                <br></br>
                <br></br>
              </p>
              <div className="home-author1">
                <span role="quote-author" className="home-name1">
                  Jonas S.
                </span>
                <span role="quote-origin" className="home-origin1">
                  Eigenaar
                </span>
              </div>
            </div>
            <div className="quote">
              <img
                alt="image"
                src="/assets/Jochem.png"
                role="quote-avatar"
                className="home-image09"
              />
              <p role="quote-content" className="home-quote5">
                <span className="home-text48">
                  &quot;5 plaatsingen met 1,000 kandidaten die geleverd zijn
                  door SourcingSpace. Super werk jongens!&quot;
                </span>
                <br></br>
                <span> </span>
                <br></br>
              </p>
              <div className="home-author2">
                <span role="quote-author" className="home-name2">
                  <span>Jochem V.</span>
                  <br></br>
                </span>
                <span role="quote-origin" className="home-origin2">
                  Recruiter
                </span>
              </div>
            </div>
            <div className="home-controls">
              <div id="quote-previous" className="home-previous">
                <img
                  alt="image"
                  src="/playground_assets/frame.svg"
                  className="home-image10"
                />
              </div>
              <div id="quote-next" className="home-next">
                <img
                  alt="image"
                  src="/playground_assets/frame.svg"
                  className="home-image11"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <DangerousHTML
            html={`<script>
  /*
              Quote Slider - Code Embed
              */

              let current = 1;

              const nextButton = document.querySelector("#quote-next");
              const previousButton = document.querySelector("#quote-previous");
              const quotes = document.querySelectorAll(".quote");

              if(nextButton&&previousButton){
                nextButton.addEventListener("click", () => {
                quotes.forEach((quote) => {
                quote.classList.remove("active-quote");
                });

                current == quotes.length ? current = 1 : current++
                quotes[current-1].classList.add("active-quote")
                });

                previousButton.addEventListener("click", () => {
                quotes.forEach((quote) => {
                quote.classList.remove("active-quote");
                });

                current == 1 ? current = quotes.length : current--
                quotes[current-1].classList.add("active-quote")
                });

              }
</script>
`}
          ></DangerousHTML>
        </div>
      </section>
      <section className="home-banner">
        <div className="home-container2">
          <div className="home-header1">
            <h2 className="home-title1">
              <span>Vergroot je succes met ons</span>
              <br></br>
            </h2>
            <p className="home-description2">
              <span>Plan een gratis demo in met een van onze experts.</span>
              <br></br>
              <span>
                In 15 minuten leggen we je uit wat wij voor je kunnen betekenen.
              </span>
              <br></br>
            </p>
          </div>
          <a
            href="mailto:info@sourcingspace.nl?subject=Wij zijn geïnteresseerd in een gratis demo"
            className="home-link2 button"
          >
            <span>
              <span>Demo inplannen</span>
              <br></br>
            </span>
          </a>
        </div>
      </section>
      <section className="home-mission1">
        <div className="home-content3 mission">
          <h2 className="home-text63 mission">
            <span>Onze missie</span>
            <br></br>
          </h2>
          <div className="home-description3">
            <p className="home-text66">
              <span>
                Bij ons zijn gelijke kansen de hoogste prioriteit. Zo zien we
                dat mensen met een goed LinkedIn netwerk meer kans hebben op een
                baan dan mensen zonder een goed netwerk.
              </span>
              <br></br>
            </p>
          </div>
        </div>
      </section>
      <section className="home-galleries">
        <div className="home-gallery-desktop">
          <div className="home-column">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1524758870432-af57e54afa26?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDV8fHdvcmtzcGFjZXxlbnwwfHx8fDE2NzA5NjI0Nzk&amp;ixlib=rb-4.0.3&amp;w=1300"
              className="home-image12"
            />
          </div>
          <div className="home-column1">
            <div className="home-row2">
              <div className="home-yellow"></div>
              <img
                alt="image"
                src="/playground_assets/pexels-darina-belonogova-8373875%203-1300w.png"
                className="home-image13"
              />
            </div>
            <div className="home-row3">
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1664575601786-b00156752b61?ixid=Mnw5MTMyMXwxfDF8YWxsfDIxNnx8fHx8fDJ8fDE2NzA4ODA1ODE&amp;ixlib=rb-4.0.3&amp;w=500"
                className="home-image14"
              />
              <img
                alt="image"
                src="/playground_assets/logo-color-1300w.png"
                className="home-image15"
              />
            </div>
          </div>
        </div>
        <div className="home-gallery-mobile">
          <div className="home-column2">
            <img
              alt="image"
              src="/playground_assets/pexels-darina-belonogova-7959670%201-700h.png"
              className="home-image16"
            />
            <img
              alt="image"
              src="/playground_assets/pexels-darina-belonogova-7964869%201-500w.png"
              className="home-image17"
            />
          </div>
          <div className="home-column3">
            <div className="home-yellow1"></div>
            <img
              alt="image"
              src="/playground_assets/pexels-darina-belonogova-8373875%203-1300w.png"
              className="home-image18"
            />
            <div className="home-row4">
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                className="home-image19"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="home-mission2">
        <div className="home-content4">
          <cite className="home-quote6">
            “Wij zoeken vaak creatieve geesten, dat is een uitdaging op
            zichzelf, maar met SourcingSpace wordt deze uitdaging een stuk
            makkelijker gemaakt!”
          </cite>
          <div className="home-author3">
            <img
              alt="image"
              src="/assets/Sabine.png"
              className="home-avatar"
            />
            <div className="home-details1">
              <span className="home-name3">
                Sabine G.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-origin3">Recruiter</span>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
      <div>
        <DangerousHTML
          html={`<style>
    .partner-container:hover .partner-image{
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(75deg) brightness(101%) contrast(102%);
    }
</style>`}
        ></DangerousHTML>
      </div>
    </div>
  )
}

export default Home
