import React from 'react'

import PropTypes from 'prop-types'

import Branding from './branding'
import './footer.css'

const Footer = (props) => {
  return (
    <footer className="footer-footer">
      <div className="footer-container">
        <div className="footer-container1">
          <div className="footer-container2">
            <div className="footer-information">
              <div className="footer-content">
                <div className="footer-header">
                  <Branding rootClassName="branding-root-class-name"></Branding>
                  <p className="footer-text">{props.text}</p>
                </div>
                <div className="footer-contact-list">
                  <div className="footer-contact">
                    <svg viewBox="0 0 1024 1024" className="footer-icon">
                      <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                    </svg>
                    <span className="footer-text1">{props.text1}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className="footer-copyright">{props.Copyright}</span>
      </div>
      <div className="footer-container3">
        <a
          href="https://www.linkedin.com/company/sourcingspace/"
          target="_blank"
          rel="noreferrer noopener"
          className="footer-text2 link"
        >
          {props.Text6}
        </a>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  Copyright: '©2022 SourcingSpace All Rights Reserved.',
  text: 'SourcingSpace is een Talent Agency dat unbiased geschrikte kandidaten zoekt en benaderd om iedereen gelijke kansen te geven. Onze prioriteit is gelijke kansen voor iedereen. SourcingSpace staat ingeschreven onder de KvK: 75057026',
  text1: 'info@sourcingspace.nl',
  Text6: 'LinkedIn',
}

Footer.propTypes = {
  Copyright: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  Text6: PropTypes.string,
}

export default Footer
